import React, { useState } from 'react'
import PdfViewer from './PdfViewer';
import { Box, Button, ButtonGroup, Container, styled, Tooltip, Typography, Zoom } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Resume = () => {
  const [height, setHeight] = useState(750);
  const [error, setError] = useState("");
console.log("Hello, world! ;)" ,error)

  const handleMin = () => setHeight(500);
  const handleMax = () => setHeight(1000);
  const handleIncrement = () => height === 1000 ? setError("Maxed out") : setHeight(height + 50);
  const handleDecrement = () => height === 500 ? setError("Minned out") : setHeight(height - 50);
  const handleDownload = () => window.open('./MatthewKohn-SoftwareEngineer-resume.pdf');

  return (
    <ResumeContainer>
      <ControlPanel>
        <SizingBox>
          <StyledButtonGroup>
            <Button variant="outlined" onClick={ handleMin } >Min</Button>
            <Button variant="contained" onClick={ handleDecrement }>
              <RemoveIcon />
            </Button>
            <StyledTypography>
              { height/10 }%
            </StyledTypography>
            <Button variant="contained" onClick={ handleIncrement }>
              <AddIcon />
            </Button>
            <Button variant="outlined" onClick={ handleMax } >Max</Button>
          </StyledButtonGroup>
        </SizingBox>
        <DownloadBox>
          <Tooltip
            title={"Download PDF of Matthew Kohn's resume"}
            placement="top"
            TransitionComponent={Zoom}
          >
            <DownloadButton variant="contained" onClick={ handleDownload } >
              <DownloadIcon />
            </DownloadButton>
          </Tooltip>
        </DownloadBox>
      </ControlPanel>
      <PdfBox>
        <PdfViewer height={ height } />  
      </PdfBox>
    </ResumeContainer>
  )
}

export default Resume;

const ResumeContainer = styled(Container)({
  height: '75vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
});

const ControlPanel = styled(Box)({
  display: 'inherit',
  justifyContent: 'center',
  margin: '0 0 15px',
  width: '500px',
});

const SizingBox = styled(Box)({
  display: 'inherit',
  justifyContent: 'space-between',
  '@media (max-width: 900px)': {
    display: 'none'
  }
});

const StyledButtonGroup = styled(ButtonGroup)({
  display: 'inherit',
  alignItems: 'center',
  
});

const StyledTypography = styled(Typography)({
  padding: '1px 5px',
  width: '42px',
  textAlign: 'center',
});

const DownloadBox = styled(Box)({
  position: 'absolute',
  right: '25%',
  '@media (max-width: 900px)': {
    position: 'relative',
    margin: '0 auto',
    right: 0,
  }
});

const DownloadButton = styled(Button)({
  width: '80px',

});

const PdfBox = styled(Box)({
  overflowY: 'scroll',
  overflowX: 'hidden',
  scrollbarGutter: 'stable',
  '@media (max-width: 600px)': {
    maxWidth: '95%',
    overflowX: 'scroll',
  }
});