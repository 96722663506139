import React, { useState } from 'react'
import MessageForm from './MessageForm';
import ConfirmDialog from './dialog/ConfirmDialog';
import ErrorDialog from './dialog/ErrorDialog';
import HelpDialog from './dialog/HelpDialog';

const ContactPage = () => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);

  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => setOpenConfirm(false);
  
  const handleOpenError = () => setOpenError(true);
  const handleCloseError = () => setOpenError(false);
  
  const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  return (
    <>
      <ConfirmDialog
        openConfirm={ openConfirm }
        onCloseConfirm={ handleCloseConfirm }
      />
      <ErrorDialog
        openError={ openError }
        onCloseError={ handleCloseError }
      />
      <HelpDialog
        openHelp={ openHelp }
        onCloseHelp={ handleCloseHelp }
      />
      <MessageForm 
        onOpenConfirm={ handleOpenConfirm }
        onOpenError={ handleOpenError }
        onOpenHelp={ handleOpenHelp }
      />    
    </>
  )
}

export default ContactPage