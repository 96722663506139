import { Card, CardContent, CardHeader, Link, styled, Typography } from '@mui/material';
import React from 'react'

const BlogCard = ({ blog }) => {
  const { title, published, url, description } = blog;

  return (
    <StyledCard raised>
      <StyledLink 
        href={ url } 
        target="_blank"
        rel="noopener noreferrer"
      >
      <CardHeader title={ title } subheader={ published }/>
      <CardContent>
        <Description variant="body1">
          { description }
        </Description>
      </CardContent>
      </StyledLink>
    </StyledCard>
  )
}

export default BlogCard


const StyledCard = styled(Card)(({ theme }) => `
  margin: 10px;
  padding: 15px;
  width: 300px;
  height: 290px;
  :hover {
    background: ${theme.palette.secondary.light};
  }
  @media (max-width: 400px) {
    width: auto;
    height: auto;
    margin: 10px 0;
    padding: 10px 5px;
  }
`);

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const Description = styled(Typography)(({ theme }) => `
  // :hover {
  //   font-weight: 700;
  //   font-size: 1rem;
  //   line-height: 1.4rem;
  // }
  @media (max-width: 400px) {
    font-size: 0.9rem;
  }
`);
