import React from 'react';
import { ButtonGroup, IconButton, styled, Tooltip, Zoom } from '@mui/material';
// import ArticleIcon from '@mui/icons-material/Article';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import EmailIcon from '@mui/icons-material/Email';
import { devUrl, githubUrl, linkedInUrl, twitterUrl, userEmailTo } from '../../data/socialData';


const SocialButtons = () => {
  const btnData = [
    // {
    //   label: "Resume for Matthew Kohn",
    //   url: resumeUrl,
    //   jsx: <ArticleIcon />
    // },
    { 
      label: "LinkedIn",
      url: linkedInUrl, 
      jsx: <LinkedInIcon /> 
    },
    { 
      label: "GitHub",
      url: githubUrl, 
      jsx: <GitHubIcon /> 
    },
    { 
      label: "Dev.to",
      url: devUrl, 
      jsx: <LogoDevIcon /> 
    },
    { 
      label: "Twitter",
      url: twitterUrl, 
      jsx: <TwitterIcon /> 
    }
  ];

  const buttonsJsx = btnData.map((btn, index) => (
    <a href={ btn.url } key={ index } target='_blank' rel="noopener noreferrer">
      <Tooltip 
        title={ btn.label }
        placement="top"
        TransitionComponent={Zoom}
      >
        <StyledIconButton>
          { btn.jsx }
        </StyledIconButton>  
      </Tooltip>
    </a>
  ))

  const openUserEmail = () => {
    // window.location = userEmailTo;
    window.open(userEmailTo, "_blank");
  }
  
  return (
    <>
      <ButtonGroup >
        { buttonsJsx }
        <Tooltip 
          title="Email me!"
          placement="top"
          TransitionComponent={Zoom}
        >
          <StyledIconButton onClick={ () => openUserEmail() } >
            <EmailIcon />
          </StyledIconButton>
        </Tooltip>
      </ButtonGroup>
    </>
  )
}

export default SocialButtons

const StyledIconButton = styled(IconButton)({
  margin: '0 10px 25px',
  '@media (max-width: 600px)': {
    margin: '0 2px 20px',
    // display: 'flex',
    // flexWrap: 'wrap',
  }
})