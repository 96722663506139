import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Box, Button, styled, TextField, Typography } from '@mui/material';
import { serviceID, templateID, publicKey } from '../data/emailjsData';


function MessageForm({ onOpenConfirm, onOpenError, onOpenHelp }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const form = useRef();
  // const navigate = useNavigate();

  const handleInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  
  const clearForm = () => {
    setFormData({
      name: "",
      email: "",
      message: ""
    });
  }

  const sendEmail = (e) => {
    e.preventDefault();
    if (formData.message === "") {
      onOpenHelp();
    } else {
      emailjs.sendForm(serviceID, templateID, form.current, publicKey)
      .then((result) => {
        console.log('SUCCESS!', result.status, result.text, result);
        onOpenConfirm();
        clearForm();
        // navigate('/');
      }, (error) => {
        console.log('FAILED...', error.text);
        onOpenError();
      });
    }
  }

  return (
    <ContactForm
      component="form"
      noValidate
      autoComplete="off"
      ref={ form }
      onSubmit={ sendEmail }
    >
      <UserInput>
        <MessageTitle variant="h3">Send me a message!</MessageTitle>
        <TextField
          autoFocus
          required
          margin="normal"
          label="Your name"
          value={ formData.name }
          onChange={ handleInput }
          name="name" />
        <TextField
          label="Email"
          margin="normal"
          value={ formData.email }
          onChange={ handleInput }
          type="email"
          name="email" />

      </UserInput>
      <MessageBox>
        <TextField
          label="Message"
          multiline
          fullWidth
          rows={ 4 }
          value={ formData.message }
          onChange={ handleInput }
          name="message" />
        <BtnBox>
          <Button 
            variant="outlined" 
            onClick={ clearForm }
            size="large"
          >
            clear
          </Button>
          <Button 
            fullWidth 
            variant="contained" 
            size="large"
            type="submit" 
            value="Send"
          >
            send
          </Button>
        </BtnBox>
      </MessageBox>
    </ContactForm>
  );
}

export default MessageForm

const ContactForm = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '30px 0',
  textAlign: 'center',
  '@media (min-width: 800px)': {
    maxWidth: '600px',
    margin: 'auto',
  },
});

const UserInput = styled(Box)({
  width: '80%',
  display: 'inherit',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (max-width: 660px)': {
    width: '100%',
  },
});

const MessageBox = styled(Box)({
  margin: '10px',
  width: '80%',
  '@media (max-width: 660px)': {
    width: '100%',
  },
});

const BtnBox = styled(Box)({
  marginTop: '5px',
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 660px)': {
    flexDirection: 'column-reverse',
  },
});

const MessageTitle = styled(Typography)({
  '@media (max-width: 800px)': {
    fontSize: '40px',
  },
  '@media (max-width: 450px)': {
    fontSize: '28px',
  }
})