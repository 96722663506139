import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { AppBar, Avatar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Button, styled, Link, Tooltip, Zoom } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
// import SocialButtonGroup from './SocialButtonGroup';
import { headshot1Src } from '../../data/imageData';

const drawerWidth = 240;
const navItems = [
  { name: 'Home', link: '/' },
  // { name: 'About', link: '/about/bio' },
  { name: 'Projects', link: '/projects' },
  { name: 'Resume', link: '/resume'},
  { name: 'Blogs', link: '/blogs' },
  { name: 'Contact', link: '/contact' },
];
const headshotAlt = "Matthew Kohn's headshot";
const title = "Matthew Kohn";

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <StyledBox onClick={ handleDrawerToggle } >
      <a href={ "https://www.linkedin.com/in/matthewkohn1/" } target='_blank' rel="noopener noreferrer">
        <Avatar
          src={ headshot1Src.default }
          alt={ headshotAlt }
        />
      </a>
      <Typography variant="h6" sx={{ my: 2 }}>
        { title }
      </Typography>
      <Divider />
      <List>
        { navItems.map((item, index) => (
          <ListItem key={ index } disablePadding>
            <ListItemButton onClick={ () => navigate(item.link) } sx={{ textAlign: 'center' }}>
              <ListItemText primary={ item.name } />
            </ListItemButton>
          </ListItem>
        )) }
      </List>
      {/* <SocialButtonGroup /> */}
    </StyledBox>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={ handleDrawerToggle }
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Link href={"https://www.linkedin.com/in/matthewkohn1/"} target='_blank' rel="noopener noreferrer">
            <Tooltip 
              title="Go To LinkedIn for Matthew Kohn"
              placement="bottom-start"
              TransitionComponent={Zoom}
            >
              <Avatar
                src={ headshot1Src.default }
                alt={ headshotAlt }
              />
            </Tooltip>
          </Link>
          <Title
            variant="h6"
            component="div"
          >
            {title}
          </Title>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            { navItems.map((item, index) => (
              <Button onClick={() => navigate(item.link)} key={ index } sx={{ color: '#fff' }}>
                { item.name }
              </Button>
            )) }
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={ container }
          variant="temporary"
          open={ mobileOpen }
          onClose={ handleDrawerToggle }
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          { drawer }
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        
      </Box>
    </Box>
  );
}

// DrawerAppBar.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

export default DrawerAppBar;


const Title = styled(Typography)({
  flexGrow: 1, 
  display: { xs: 'none', sm: 'block' },
  margin: '0 0 0 20px',
})

const StyledBox = styled(Box)({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '10px 0'
})