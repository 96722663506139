const resumeUrl = 'https://docs.google.com/document/d/1--BFryZeOBszTzyr7zhBwHV1jya3E_CezAqDuOE0zF8/edit?usp=sharing';
const linkedInUrl = 'https://www.linkedin.com/in/matthewkohn1/';
const githubUrl = 'https://github.com/matthewkohn';
const devUrl = 'https://dev.to/matthewkohn';
const twitterUrl = 'https://twitter.com/MatthewKohn5';
const userEmailTo = 'mailto:kohney319m@gmail.com';

export {
  resumeUrl,
  linkedInUrl,
  githubUrl,
  devUrl,
  twitterUrl,
  userEmailTo,
}