import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { blogs } from '../data/blogData';
import BlogCard from './BlogCard';

const BlogsPage = () => {
  const blogsList = blogs.map((b) => (
    <BlogCard key={b.id} blog={b} />
  ))

  return (
    <BlogsBox>
      <BlogsTitle variant="h2">Tech Articles</BlogsTitle>
      <ListBox>
        { blogsList }
      </ListBox>
    </BlogsBox>
  )
}

export default BlogsPage

const BlogsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '10px'
})

const BlogsTitle = styled(Typography)({
  '@media (max-width: 700px)': {
    fontSize: '40px',
  },
  '@media (max-width: 480px)': {
    fontSize: '28px',
    textAlign: 'center',
  },
})

const ListBox = styled(Box)({
  display: 'inherit',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignContent: 'center',
  justifyContent: 'center',
})