import React from 'react';
import { Document,Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Resume from '../assets/MatthewKohn-SoftwareEngineer-resume.pdf';

const PdfViewer = ({ height }) => {

  return (
    <>
      <Document 
        file={ Resume }
        title="Resume for Matthew Kohn"
        author="Matthew Kohn"
        // pageMode="fullScreen"
        // size="A4"
        // style={ styles.page }
        // loading={<Typography>Loading...</Typography>}
        // options={{ workerSrc: "../../public/pdf.worker.js" }}
        >
        <Page pageNumber={1} height={ height } />
      </Document>
    </>
  )
}

export default PdfViewer;