const blogs = [
  {
    id: 1,
    title: "Code Along with Fetch API",
    published: "November 15, 2021",
    url: "https://dev.to/matthewkohn/code-along-with-fetch-api-1h18",
    description: "A beginner's tutorial on how to use the Fetch API with vanilla JavaScript to fetch random dog pictures from the Dog CEO API."
  },
  {
    id: 2,
    title: "Control Forms in React",
    published: "March 3, 2022",
    url: "https://dev.to/matthewkohn/control-forms-in-react-6p6",
    description: "A walkthrough of how to keep track of user's form inputs using state in a React application using controlled forms."
  },
  {
    id: 3,
    title: "Ruby Enumerators- Syntax Basics",
    published: "June 3, 2022",
    url: "https://dev.to/matthewkohn/ruby-enumerators-syntax-basics-1cmh",
    description: "Explains the syntax and function of ruby enumerators."
  },
  {
    id: 4,
    title: "Cookies & Sessions in Rails",
    published: "July 30, 2022",
    url: "https://dev.to/matthewkohn/cookies-sessions-in-rails-54b8",
    description: "Describes what cookies & sessions are in rails, and how to set up a Rails API with Postgres that keeps track of a user's cookies."
  },
  {
    id: 5,
    title: "Keeping Track of React State with useContext()",
    published: "October 5, 2022",
    url: "https://dev.to/matthewkohn/keeping-track-of-react-state-with-usecontext-27bo",
    description: "Demonstrates how to utilize React's useContext hook to keep track of global state used in various parts of your app."
  },
  {
    id: 6,
    title: "Missing GitHub Contributions?",
    published: "November 6, 2022",
    url: "https://dev.to/matthewkohn/missing-github-contributions-double-check-configurations-1ihm",
    description: "Explains how I figured out why all of my GitHub contributions weren't appearing in my profile, and how I fixed it."
  }
]

export { blogs }