import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorDialog = ({ openError, onCloseError }) => {
  return (
    <Dialog
      open={ openError }
      TransitionComponent={ Transition }
      keepMounted
      onClose={ onCloseError }
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Sorry!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Something went wrong. Please keep exploring my portfolio, and reach out to me at kohney319m@gmail.com.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default ErrorDialog
