import React from 'react';
import { Container, styled } from '@mui/material';
import SocialButtons from './SocialButtons';

const SocialButtonGroup = () => {
  return (
    <BtnContainer>
      <SocialButtons />
    </BtnContainer>
  )
}

export default SocialButtonGroup

const BtnContainer = styled(Container)({
  display: 'inherit',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  '@media (max-width: 300px)': {
    flexWrap: 'wrap',
    maxWidth: '200px',
    margin: '0 20px',
  },
});