import React from "react";
import { Container, styled } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Header from "./components/navigation/Header";
import HomePage from "./components/HomePage";
import BlogsPage from "./components/BlogsPage";
import ProjectsPage from "./components/ProjectsPage";
import SocialButtonGroup from "./components/navigation/SocialButtonGroup";
import Contact from "./components/ContactPage";
import Resume from "./components/Resume";

function App() {
  return (
    <AppContainer>
      <Header/>
      <Body>
        <Routes>
          <Route index element={ <HomePage /> } />
          <Route path='projects' element={ <ProjectsPage /> } />
          <Route path='blogs' element={ <BlogsPage /> } />
          <Route path='contact' element={ <Contact /> } />
          <Route path='resume' element={ <Resume /> } />
        </Routes>
      </Body>
      <SocialButtonGroup />
    </AppContainer>
  );
}

export default App;

const AppContainer = styled(Container)(({ theme }) => `
  background: ${theme.palette.primary.light};
  color: ${theme.palette.primary.dark};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  min-width: 100vw;
  margin: 0 auto;
  @media (max-width: 660px): {
    margin: auto;
    align-items: flex-start;
  }
`);


const Body = styled(Container)({
  width: '100%',
  '@media (max-width: 400px)': {
    padding: 0,
  },
});