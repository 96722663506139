import React from 'react';
import { styled, Box, Container, Typography } from '@mui/material';
import { projectData } from '../data/projectData';
import ProjectCard from './ProjectCard';
import GitHubIcon from '@mui/icons-material/GitHub';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const ProjectsPage = () => {
  const projectCards = projectData.map((p) => (
    <ProjectCard
      key={ p.id }
      project={ p }
    />
  ));
  
  return (
    <ProjectsBox>
      <ProjectTitle variant="h2">Featured Web Applications</ProjectTitle>
      <Instructions>
        <Instruction variant="body1">Click on an image to visit the deployed site.</Instruction>
        <Instruction variant="body2">Visit my <StyledGitHubIcon fontSize="small"/> GitHub repository for any project, or toggle <LightbulbIcon fontSize="small"/> to learn more.</Instruction>
      </Instructions>
      <CardContainer>
        { projectCards }
      </CardContainer>
    </ProjectsBox>
  )
}

export default ProjectsPage

const ProjectsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '0 0 5px',
  '@media (max-width: 1200px)': {
    justifyContent: 'flex-start',
  },
  '@media (max-width: 940px)': {
    textAlign: 'center',
  }
});

const ProjectTitle = styled(Typography)({
  // margin: '0 0 30px',
  '@media (max-width: 830px)': {
    fontSize: '40px',
  },
  '@media (max-width: 560px)': {
    fontSize: '28px',
    textAlign: 'center',
  }
})

const CardContainer = styled(Container)({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '20px 0',
  '@media (max-width: 800px)': {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

})

const Instructions = styled(Container)({
  textAlign: 'center',
})

const Instruction = styled(Typography)({
  fontStyle: 'italic',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 450px)': {
    display: 'none',
  },
})

const StyledGitHubIcon = styled(GitHubIcon)({
  margin: '3px',
})