// Images
const storytellerImg = require('../assets/storyteller1.png');
const foodcourtImg = require('../assets/foodcourt1.png');
const allergyImg = require('../assets/allergyTracker1.png');

// URLs
const storytellerGitHub = 'https://github.com/matthewkohn/storyteller';
const storytellerDemo = 'https://youtu.be/5FGV2X0oCjo';
const foodcourtGithub = 'https://github.com/matthewkohn/food-court-delivery';
const foodcourtDemo = 'https://youtu.be/fko4hY4AE2c';
const allergyGithub = 'https://github.com/matthewkohn/allergy-tracker-client';
const allergyDemo = 'https://youtu.be/CfGinx93U7k';


const projectData = [
  {
    id: 1,
    title: 'Storyteller',
    imageURL: storytellerImg.default,
    gitHubURL: storytellerGitHub,
    demoURL: storytellerDemo,
    shortDescription: 'A turn-based creative writing app, where the user can create new stories and contribute to existing stories, one paragraph at a time.',
    techUsed: `ReactJS, Material UI, DraftJS, React Router, Ruby on Rails, ActiveRecord, PostgreSQL, BCrypt Gem.`
  },
  {
    id: 2,
    title: 'Foodcourt Delivery',
    imageURL: foodcourtImg.default,
    gitHubURL: foodcourtGithub,
    demoURL: foodcourtDemo,
    shortDescription: 'Explore a variety of fictional food menus, and create or order dishes from any of them. ',
    techUsed: 'ReactJS, Material UI, React Router, Ruby on Rails, Faker & BCrypt Gems, ActiveRecord, PostgreSQL.'
  },
  {
    id: 3,
    title: 'Allergy Tracker',
    imageURL: allergyImg.default,
    gitHubURL: allergyGithub,
    demoURL: allergyDemo,
    shortDescription: `Allergy Tracker is an allergy cheat-sheet I've used in a restaurant that allows the user to create and use flash-cards to study menu items and their associated food allergies.`,
    techUsed: 'ReactJS, Material UI, Ruby on Rails, ActiveRecord, Sinatra, MySQL.'
  }
]

export { projectData }