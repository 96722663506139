import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const HelpDialog = ({ openHelp, onCloseHelp }) => {
  return (
    <Dialog
      open={ openHelp }
      TransitionComponent={ Transition }
      keepMounted
      onClose={ onCloseHelp }
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Whoops!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Please type a message and try again.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default HelpDialog
