import React from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Slide, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const ConfirmDialog = ({ openConfirm, onCloseConfirm }) => {
  const navigate = useNavigate();

  return (
    <StyledDialog
      open={ openConfirm }
      TransitionComponent={ Transition }
      keepMounted
      onClose={ onCloseConfirm }
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Thank you!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Your message has been sent to Matthew Kohn at kohney319m@gmail.com.
        </DialogContentText>
      </DialogContent>
      <ConfirmButton 
        variant="contained" 
        onClick={ () => navigate('/') }
      >
        Continue to Home Page
      </ConfirmButton>
    </StyledDialog>
  )
}

export default ConfirmDialog

const StyledDialog = styled(Dialog)({
  textAlign: 'center',
})

const ConfirmButton = styled(Button)({
  padding: '20px',
  margin: '20px'
})