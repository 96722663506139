import React, { useState } from 'react';
import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Link, styled, Tooltip, Typography, Zoom } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LinkIcon from '@mui/icons-material/Link';

const ProjectCard = ({ project }) => {
  const { title, imageURL, gitHubURL, demoURL, shortDescription, techUsed } = project;
  const [flip, setFlip] = useState(true);

  const handleClick = () => {
    setFlip(!flip);
  }

  return (
    <StyledProject raised>
      <CardHeader title={ title } />
      {
        flip ?
        <StyledCardContent>
          <StyledLink 
          href={ demoURL } 
          target="_blank"
          rel="noopener noreferrer"
          >
            <Tooltip
              title={`Video demo of ${title} app`}
              placement="bottom"
              TransitionComponent={Zoom}
            >
              <StyledCardMedia
                component="img"
                height="200"
                src={ imageURL }
                alt={ title }
                />
            </Tooltip>
          </StyledLink>  
        </StyledCardContent>
      :
        <StyledCardContent>
          <TechBox>
            <Description variant="body2">{ shortDescription }</Description>
          </TechBox>
          <TechBox>
            <Typography variant="body2"><strong>Stack:</strong> { techUsed }</Typography>
          </TechBox>
        </StyledCardContent>
      }
      <StyledCardActions>
        <StyledLink 
          href={ gitHubURL } 
          target="_blank"
          rel="noopener noreferrer"
        > 
        <Tooltip 
          title={`GitHub repository for ${ title }`}
          placement="top"
          TransitionComponent={Zoom}
        >
          <IconButton>
            <GitHubIcon />
          </IconButton>
        </Tooltip>
        </StyledLink>
        <Tooltip 
          title="Toggle between Info and Demo link"
          placement="top"
          TransitionComponent={Zoom}
        >
          <IconButton onClick={ handleClick } >
            { flip ? <LightbulbIcon /> : <LinkIcon /> }
          </IconButton>
        </Tooltip>
      </StyledCardActions>
    </StyledProject>
  )
}

export default ProjectCard

const StyledProject = styled(Card)({
  margin: '0 auto',
  maxWidth: '300px',
  width: '30%',
  padding: '30px 20px',
  borderRadius: '20px',
  display: 'inherit',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  '@media (max-width: 1200px)': {
    width: '100%',
    margin: '10px auto',
    justifyContent: 'space-around',
  },
  '@media (max-width: 460px)': {
    width: 'auto',
  }
})

const StyledCardMedia = styled(CardMedia)({
  maxWidth: '250px',
  border: '2px solid black',
  borderRadius: '5px',
  padding: '5px',
  margin: 'auto',
  objectFit: 'cover',
  height: 'auto',
  '@media (max-width: 1200px)': {
    // minHeight: '405px',
  },

})

const StyledCardContent = styled(CardContent)({
  height: '100%',
  minHeight: '214px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '0 12px',
  '@media (max-width: 450px)': {
    width: '100%',
    minHeight: 'fit-content',
    // height: 'auto',
  }
})

const StyledLink = styled(Link)({
  textDecoration: 'none',
})

const Description = styled(Typography)({
  textAlign: 'left',
})

const TechBox = styled(Box)(({ theme }) => `
  border: 3px solid ${theme.palette.primary.main};
  border-radius: 8px;
  background: ${theme.palette.primary.light};
  padding: 5px 9px;
  margin: 3px 0 0;
  text-align: left;
`)

const StyledCardActions = styled(CardActions)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-evenly',
})