import React from 'react';
import { Box, Container, styled, Typography } from '@mui/material';
import { headshotSrc } from '../data/imageData';

const HomePage = () => {

  return (
    <HomeContainer>
      <Welcome>
        <HeadshotBox 
          component="img" 
          src={ headshotSrc.default }
          alt="Matthew Kohn's headshot"
        />
        <TitleBox>
          <Name variant="h3" component="h2">Matthew Kohn</Name>
          <Title variant="h4" component="h3">Software Engineer</Title>
          <hr />
          <Intro variant="body1">Welcome to my portfolio!</Intro>
          <Intro variant="body1">I am a software engineer specializing in building full-stack responsive web applications using JavaScript, ReactJS, Ruby on Rails, and PostgreSQL.</Intro>
          <Intro variant="body1">I'm currently developing projects using Wordpress, PHP, Typescript and NodeJS to expand my tech stack.</Intro>
          <Intro variant="body1">Please check out my projects, resume, and blogs, and contact me with any questions.</Intro>
        </TitleBox>
      </Welcome>
    </HomeContainer>
  )
}

export default HomePage


const HomeContainer = styled(Container)({
  height: 'auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-around',
  '@media (max-width: 660px)': {
    display: 'block',
    alignItems: 'space-evenly',
  },
});

const Welcome = styled(Container)({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 0 100px',
  '@media (max-width: 500px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const HeadshotBox = styled(Box)({
  width: '300px',
  height: '300px',
  borderRadius: '50%',
  boxShadow: '2px 5px 5px #ddc',
  '@media (max-width: 1000px)': {
    width: '200px',
    height: '200px',
  },
  '@media (max-width: 660px)': {
    width: '140px',
    height: '140px',
  },
})

const TitleBox = styled(Box)({
  width: '60%',
  '@media (max-width: 900px)': {
    textAlign: 'right',
  },
  '@media (max-width: 630px)': {
    width: '100%',
  },
  '@media (max-width: 500px)': {
    textAlign: 'center',
  },
});

const Name = styled(Typography)({
  
  '@media (max-width: 900px)': {
    fontSize: '40px',
  },
  '@media (max-width: 400px)': {
    fontSize: '28px',
  },
})

const Title = styled(Typography)({
  
  '@media (max-width: 900px)': {
    fontSize: '24px',
  },
  '@media (max-width: 400px)': {
    fontSize: '16px',
  },
})

const Intro = styled(Typography)({
  margin: '10px 0',
})
