const portfolioTheme = {
  palette: {
    primary: {
      light: '#60FFF0',
      main: '#308078',
      dark: '#18403C',
    },
    secondary: {
      light: '#57E6D7',
      main: '#49BFB3',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      'sans-serif'
    ].join(','),
    body1: {
      fontFamily: "'Montserrat', Roboto, sans-serif",
      // fontSize: 24,
      // fontWeightLight: 300,
      // fontWeightRegular: 400,
      // fontWeightBold: 700,
    },
  },
}


export { portfolioTheme }